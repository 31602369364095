import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/pro-solid-svg-icons';
import styles from './Download.module.scss';

export default class Download extends Component {
  constructor(props) {
    super(props);
    this.state = {
      csvUrl: null,
      excelUrl: null,
      pdfUrl: null
    }
  }

  getDownloadUrl = () => {
    this.setState({
      excelUrl: `${this.props.url}/billing/v1/settlement/${this.props.settlementNumber}/specification?accessCode=${encodeURI(this.props.accessCode)}`
    })
  };

  componentDidMount() {
    this.getDownloadUrl();
  }

  componentWillUnmount() {
    this.props.downloadUnmount();
  }

  render() {
    return <>
      <div className={styles.container}>
        <div className={styles.content}>
          <h3 className={styles.header}>Oppgjørsdetaljer for</h3>
          <h4>oppgjørsnr. <span className={styles.headerInvoiceNumber}>{this.props.settlementNumber}</span></h4>
          <br/>
          <div>Last ned filtype</div>
          <ul className={styles.download}>
            <li>
              Oppgjørsspesifikasjon - Excel
              <a href={this.state.excelUrl} target={'_blank'} rel={'noopener noreferrer'}>
                <span className={styles.downloadItemIcon}>
                  <FontAwesomeIcon icon={faDownload}/>
                </span>
              </a>
            </li>
          </ul>
          <form action="/">
            <input type="submit" className={styles.backBtn} alt={'Back button'} value="Tilbake" />
          </form>
        </div>
      </div>
    </>;
  }
}
